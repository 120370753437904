import axios from 'axios';
import { isArray, remove, uniq, sortBy } from 'lodash-es';
import qs from 'qs';
import CustomError from '@/errors/CustomError';
import IgnoreError from '@/errors/IgnoreError';
import { makeSimpleParams, makePageParams, alertMessage, wait } from '@/utils/apiUtil';
import options from '@/constants/options';
import { getAccessToken, getRefreshToken, resetToken, initTokenByRefresh } from '@/token';
import { CATEGORY } from './constants/constants';

let failedQueue: any[] = []; // 요청 실패한(status: 401) api 리스트
const processQueue = (accessToken: string) => {
  failedQueue.forEach((obj) => {
    obj.resolve(accessToken);
  });
  failedQueue = [];
};

let isRefreshing = false;
const REFRESH_URL = '/admin/refresh';
const instance = axios.create({
  timeout: 20000,
  // baseURL: 'http://localhost:6431',
  baseURL: process.env.REACT_APP_API_HOST ?? 'http://localhost:6431',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getAccessToken()}`,
  },
  withCredentials: true,
});

instance.interceptors.response.use(
  (res) => {
    if (res.status === 200) {
      return res.data;
    } else {
      console.error('res.status is not 200.');
    }
  },
  async (error: any) => {
    console.log('instance error.config', error?.config);
    console.error('api error.response:', error?.response);

    if (error.config.url === REFRESH_URL) {
      // refreshToken api의 경우 실패하면, 바로 에러처리 -> logout
      throw error;
    }
    if (error?.response.status !== 401) {
      throw error;
    }

    // 401 에러만 처리~~
    const originalRequest = error.config;
    if (originalRequest._retry) {
      // 401에러로 인해 refreshToken api요청으로 새로운 accessToken을 받아서 재실행했는데, 또 401에러가 발생하면, 재시도 하지 않는다.
      throw error;
    }
    originalRequest._retry = true;
    /**
     * 여러 건이 동시에 401에러로 실패할 경우
     * 1> 1건만 refreshToken 요청
     *      -> 1-1> 성공하면, 1건의 api 재요청 + queue에 있는 api들을 재요청 시도
     *      -> 1-2> 실패하면, 로그인 화면으로 강제 이동
     * 2> 나머지 api들은 queue에 넣고, 대기중...
     */
    if (isRefreshing) {
      return new Promise(function (resolve, reject) {
        // 2>
        failedQueue.push({ resolve, reject });
      })
        .then((accessToken) => {
          originalRequest.headers.Authorization = `Bearer ${accessToken}`;
          return instance(originalRequest);
        })
        .catch((err) => {
          return Promise.reject(error);
        });
    }
    isRefreshing = true;

    try {
      // 1> refreshToken 요청
      const result: any = await auth.refresh();
      console.log('refresh result', result);
      if (!result?.accessToken) {
        throw new Error('there is not accessToken');
      }
      const parsingResult = initTokenByRefresh(result.accessToken);
      if (!parsingResult) {
        throw new Error('token parsing error');
      }

      // 1-1> 성공
      instance.defaults.headers.common.Authorization = `Bearer ${result.accessToken}`; // 공통처리
      processQueue(result.accessToken); // queue api 처리

      originalRequest.headers.Authorization = `Bearer ${result.accessToken}`;
      return instance(originalRequest); // 1건 api 처리
    } catch (e) {
      // 1-2> 실패, refreshToken api 요청이 실패하면 답이 없다.
      console.error('e', e);
      alert('로그인 해주세요.');
      auth.logout();
    } finally {
      isRefreshing = false;
    }
    throw new IgnoreError(''); // 실제 비즈니스 로직까지 에러가 전파되지만, e instanceof IgnoreError인 경우 무시한다.
  },
);

/**
 * 로그인
 */
const auth = {
  login: (params: any) => {
    return instance.post(`/admin/login`, params);
  },
  logout: () => {
    resetToken();
    window.location.href = '/';
  },
  refresh: () => {
    return instance.post(REFRESH_URL, {
      refreshToken: getRefreshToken(),
    });
  },
};

/**
 * Hot & New 관리, 장르별 인기곡 관리, 주목받는 아티스트 관리, 무드별 관리, 시대별 관리, 금영 큐레이션
 * 예외처리) 배너의 경우에는 빈 곡도 가능해야한다 (2024.02.27)
 */
const makeContentsParams = (params: any, isBanner: boolean = false) => {
  try {
    const newParams: any = {};
    ['title', 'color', 'thumbnailPath', 'tvBrand'].forEach((key: string) => {
      if (Object.keys(params).indexOf(key) !== -1) {
        newParams[key] = params[key];
      }
    });
    if (!isBanner) {
      if (Object.keys(params).indexOf('songIds') === -1 || !isArray(params['songIds'])) {
        throw new Error('sondIds is null');
      }
      if (params['songIds'].length < 1) {
        throw new Error('sondIds length is zero.');
      }
    }
    newParams['songIds'] = params['songIds'];
    return newParams;
  } catch (e) {
    alertMessage(e);
  }
  return null;
};
const contents = {
  getJobCurrentType: (pageName?: string) => {
    return instance.get(`/job/current/${pageName}`);
  },
  list: async (pageName: string, tvBrand?: string) => {
    let currentRequestString = `/curation/categories?menu=${pageName}`;

    if (tvBrand) {
      currentRequestString += `&tvBrand=${tvBrand}`;
    }

    const res: any = await instance.get(currentRequestString);
    if (!res) {
      throw new Error('res is null');
    }
    if (!res.subCategories) {
      throw new Error('res.subCategories is null');
    }
    return res.subCategories;
  },
  updateOrder: (orderSet: any, pageName: string, tvBrand?: string) => {
    let body: any = {
      menu: pageName,
      orderSet,
    };

    if (tvBrand) {
      body.tvBrand = tvBrand;
    }

    return instance.put(`/curation/order`, {
      ...body,
    });
  },
  detail: (id: string) => {
    return instance.get(`/curation/category/${id}`);
  },
  create: (params: any, pageName: string) => {
    const newParams: any = makeContentsParams(params);
    if (!newParams) return;
    return instance.post(`/curation/category`, {
      menu: pageName,
      ...newParams,
    });
  },
  update: (params: any, id: string, pageName: string) => {
    const newParams: any = makeContentsParams(params);
    if (!newParams) return;
    return instance.put(`/curation/category/${id}`, {
      menu: pageName,
      ...newParams,
    });
  },
  delete: (id: string) => {
    return instance.delete(`/curation/category/${id}`);
  },
  sampleExcel: () => {
    return `${process.env.REACT_APP_RESOURCE_HOST}/excel/song-bulk.xlsx`;
  },
  upload: (formData: any) => {
    return instance.post('/curation/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
};

/**
 * 메인배너 관리
 */
const mainBanner = {
  getJobCurrentType: (pageName?: string) => {
    return instance.get(`/job/current/${pageName}`);
  },
  list: async (pageName: string, tvBrand: string) => {
    const res: any = await instance.get(`/banner?tvBrand=${tvBrand}`);
    if (!res) {
      throw new Error('res is null');
    }
    if (!res.list) {
      throw new Error('res.list is null');
    }
    return res.list;
  },
  updateOrder: (orderSet: any) => {
    const newOrderSet = orderSet.map((obj: any) => ({
      bannerId: obj.categoryId,
      order: obj.order,
    }));
    return instance.put(`/banner/order`, {
      orderSet: newOrderSet,
    });
  },
  detail: (id: string) => {
    return instance.get(`/banner/${id}`);
  },
  create: (params: any) => {
    const newParams: any = makeContentsParams(params, true);
    if (!newParams) return;
    return instance.post(`/banner`, {
      ...newParams,
    });
  },
  update: (params: any, id: string, pageName: string) => {
    const newParams: any = makeContentsParams(params, true);
    if (!newParams) return;
    return instance.put(`/banner/${id}`, {
      ...newParams,
    });
  },
  delete: (id: string) => {
    return instance.delete(`/banner/${id}`);
  },
  sampleExcel: () => {
    return `${process.env.REACT_APP_RESOURCE_HOST}/excel/song-bulk.xlsx`;
  },
  upload: (formData: any) => {
    return instance.post('/banner/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
};

/**
 * 뮤직 비디오 재생여부
 */
const VIDEO_SWITCH_PARAMS = ['enableVideo'];

const videoSwitch = {
  current: async (pageName: string, tvBrand: string) => {
    const res: any = await instance.get(`/video-switch`);
    return res;
  },
  update: (params: any) => {
    const newParams = makeSimpleParams(params, VIDEO_SWITCH_PARAMS);
    return instance.put(`/video-switch/set`, {
      ...newParams,
    });
  },
};

/**
 * 공지사항 관리
 */
const NOTICE_PARAMS = ['category', 'koTitle', 'koContent', 'enTitle', 'enContent'];
const notice = {
  list: (params: any) => {
    return instance.get(`/notice/list?${qs.stringify(makePageParams(params, ['keyword']))}`);
  },
  create: (params: any) => {
    const newParams = makeSimpleParams(params, NOTICE_PARAMS);
    return instance.post(`/notice/create`, {
      ...newParams,
      koImage: params.koImage,
      enImage: params.enImage,
    });
  },
  detail: (id: string) => {
    return instance.get(`/notice/detail?id=${id}`);
  },
  update: (params: any, id: string) => {
    const newParams = makeSimpleParams(params, NOTICE_PARAMS);
    return instance.put(`/notice/modify`, {
      id: parseInt(id),
      ...newParams,
      koImage: params.koImage, // 필수가 아니어서 빈값도 update가능
      enImage: params.enImage,
    });
  },
  delete: (id: string) => {
    return instance.delete(`/notice/delete`, {
      data: {
        id: parseInt(id),
      },
    });
  },
};

/**
 * 회원관리
 */
const makeMemberParams = (fn: any, params: any) => {
  const newParams = fn(params, ['email', 'country', 'activatedProductId', 'tvBrand']);
  if (
    params.customDateRangeWithType.dateType !== '' &&
    params.customDateRangeWithType.startDate !== null &&
    params.customDateRangeWithType.startDate !== '' &&
    params.customDateRangeWithType.endDate !== null &&
    params.customDateRangeWithType.endDate !== ''
  ) {
    newParams['searchDateType'] = params.customDateRangeWithType.dateType;
    newParams['searchStartDate'] = params.customDateRangeWithType.startDate + 'T00:00:00.000Z';
    newParams['searchEndDate'] = params.customDateRangeWithType.endDate + 'T23:59:59.999Z';
  }
  return newParams;
};
const member = {
  list: (params: any) => {
    const newParams = makeMemberParams(makePageParams, params);
    return instance.get(`/user/list?${qs.stringify(newParams)}`);
  },
  downloadListToExcel: async (params: any) => {
    const newParams = makeMemberParams(makeSimpleParams, params);
    const data: any = await instance.get(`/user/list-excel?${qs.stringify(newParams)}`, {
      responseType: 'blob',
    });
    const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=utf-8'; // res.headers['content-type']
    const blob = new Blob([data], {
      type: contentType,
    });
    const fileName = 'Users.xlsx';

    // ie11
    // if (window.navigator.msSaveOrOpenBlob) {
    //   window.navigator.msSaveOrOpenBlob(blob, fileName);
    //   return;
    // }
    // 그외 브라우저
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();
  },
  detailBasic: (id: string) => {
    return instance.get(`/user/detail-basic?userId=${id}`);
  },
  detailProduct: (id: string, category: string) => {
    return instance.get(`/user/detail-payments?userId=${id}&category=${category}`);
  },
  sendVerificationEmail: (id: string) => {
    return instance.post(`/user/email-verification-send`, {
      userId: parseInt(id),
    });
  },
  sendPasswordEmail: (id: string) => {
    return instance.post(`/user/email-password-change-send`, {
      userId: parseInt(id),
    });
  },
  refund: (params: any) => {
    // return new Promise((resolve) => {
    //   setTimeout(resolve, 5000);
    // })
    return instance.post(`/payment/refund`, params);
  },
  unsubscription: (params: any) => {
    // return new Promise((resolve) => {
    //   setTimeout(resolve, 5000);
    // })
    return instance.post(`/payment/unsubscription`, params);
  },
  getCountry: async () => {
    const res: any = await instance.get(`/country/list`);
    if (res.countries) {
      const list = res.countries.map((obj: any) => ({
        label: obj.name,
        value: obj.code,
      }));
      return list;
    }
    return null;
  },
};

/**
 * 구매정보
 */
const makePaymentParams = (fn: any, params: any) => {
  const newParams = fn(params, ['email', 'country', 'activatedProductId', 'tvBrand']);
  if (
    params.customDateRangeWithType.dateType !== '' &&
    params.customDateRangeWithType.startDate !== null &&
    params.customDateRangeWithType.startDate !== '' &&
    params.customDateRangeWithType.endDate !== null &&
    params.customDateRangeWithType.endDate !== ''
  ) {
    newParams['searchDateType'] = params.customDateRangeWithType.dateType;
    newParams['searchStartDate'] = params.customDateRangeWithType.startDate + 'T00:00:00.000Z';
    newParams['searchEndDate'] = params.customDateRangeWithType.endDate + 'T23:59:59.999Z';
  }
  return newParams;
};
const payment = {
  list: (params: any) => {
    const newParams = makePaymentParams(makePageParams, params);
    return instance.get(`/payment/list?${qs.stringify(newParams)}`);
  },
  downloadListToExcel: async (params: any) => {
    const newParams = makeMemberParams(makeSimpleParams, params);
    const data: any = await instance.get(`/payment/list-excel?${qs.stringify(newParams)}`, {
      responseType: 'blob',
    });
    const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=utf-8'; // res.headers['content-type']
    const blob = new Blob([data], {
      type: contentType,
    });
    const fileName = 'Payment.xlsx';

    // ie11
    // if (window.navigator.msSaveOrOpenBlob) {
    //   window.navigator.msSaveOrOpenBlob(blob, fileName);
    //   return;
    // }
    // 그외 브라우저
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();
  },
};

/**
 * 프로모코드 관리
 */
const makePromotionParams = (params: any) => {
  const newParams = makeSimpleParams(params, ['code', 'productId', 'saleAmount', 'startDate', 'endDate']);
  newParams['productId'] = parseInt(newParams['productId']);
  newParams['saleAmount'] = parseFloat(newParams['saleAmount']);
  /* newParams['startDate'] = newParams['startDate'].split(' ').join('T') + '.000Z';
  newParams['endDate'] = newParams['endDate'].split(' ').join('T') + '.000Z'; */
  return newParams;
};
const promotion = {
  list: (params: any) => {
    return instance.get(`/promotion/list?${qs.stringify(makePageParams(params, ['searchCode', 'status']))}`);
  },
  create: (params: any) => {
    const newParams = makePromotionParams(params);
    return instance.post(`/promotion/create`, newParams);
  },
  detail: (id: string) => {
    return instance.get(`/promotion/detail?id=${id}`);
  },
  update: (params: any, id: string) => {
    const newParams = makePromotionParams(params);
    return instance.put(`/promotion/modify`, {
      id: parseInt(id),
      ...newParams,
    });
  },
  delete: (id: string) => {
    return instance.delete(`/promotion/delete?id=${id}`, {
      data: {
        id: parseInt(id),
      },
    });
  },
};

/**
 * 회원 곡 히스토리
 */
const makeSongLogParams = (fn: any, params: any) => {
  const newParams = fn(params, ['email', 'country', 'tvBrand']);
  if (
    params.customDateRangeWithType.startDate !== null &&
    params.customDateRangeWithType.startDate !== '' &&
    params.customDateRangeWithType.endDate !== null &&
    params.customDateRangeWithType.endDate !== ''
  ) {
    newParams['searchStartDate'] = params.customDateRangeWithType.startDate + 'T00:00:00.000Z';
    newParams['searchEndDate'] = params.customDateRangeWithType.endDate + 'T23:59:59.999Z';
  }
  return newParams;
};
const songLog = {
  list: (params: any) => {
    const newParams = makeSongLogParams(makePageParams, params);
    return instance.get(`/song-log/list?${qs.stringify(newParams)}`);
  },

  downloadListToExcel: async (params: any) => {
    const newParams = makeSongLogParams(makeSimpleParams, params);
    const data: any = await instance.get(`/song-log/list-excel?${qs.stringify(newParams)}`, {
      responseType: 'blob',
    });
    const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=utf-8'; // res.headers['content-type']
    const blob = new Blob([data], {
      type: contentType,
    });
    const fileName = 'Users.xlsx';

    // ie11
    // if (window.navigator.msSaveOrOpenBlob) {
    //   window.navigator.msSaveOrOpenBlob(blob, fileName);
    //   return;
    // }
    // 그외 브라우저
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();
  },
};

/**
 * 약관관리(이용약관, 개인정보 수집이용 동의, 개인정보처리방침)
 */
const TERMS_PARAMS = ['title', 'enforcementDate', 'koContent', 'enContent', 'tvBrand'];
const terms = {
  list: (params: any, pageName: string, tvBrand: string) => {
    return instance.get(`/term?${qs.stringify(makePageParams(params, ['keyword']))}&menu=${pageName}&tvBrand=${tvBrand}`);
  },
  create: (params: any, pageName: string) => {
    const newParams = makeSimpleParams(params, TERMS_PARAMS);
    return instance.post(`/term`, {
      menu: pageName,
      ...newParams,
    });
  },
  detail: (id: string) => {
    return instance.get(`/term/${id}`);
  },
  update: (params: any, id: string, pageName: string) => {
    const newParams = makeSimpleParams(params, TERMS_PARAMS);
    return instance.put(`/term/${id}`, {
      menu: pageName,
      ...newParams,
    });
  },
  delete: (id: string) => {
    return instance.delete(`/term/${id}`, {
      data: {
        id: parseInt(id),
      },
    });
  },
};

/**
 * 음악 추천 DB 업로드
 */
const musicRecommendUpload = {
  getJobCurrentType: () => {
    return instance.get(`/job/current/MATCH_SONG`);
  },
  sampleExcel: () => {
    return `${process.env.REACT_APP_RESOURCE_HOST}/excel/match-song.xlsx`;
  },
  getFilename: () => {
    return instance.get('/matchsong/filename');
  },
  uploadTempFile: (formData: any) => {
    return instance.post('/matchsong/upload/temp', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  operate: (filename: string) => {
    return instance.post('/matchsong/upload', {
      filename,
    });
  },
  cancel: (jobId: string) => {
    return instance.put(`/matchsong/temp/${jobId}/cancel`);
  },
};

/**
 * 빅스비 DB 업로드
 */
const bixbyUpload = {
  getJobCurrentType: () => {
    return instance.get(`/job/current/BIXBY`);
  },
  sampleExcel: () => {
    return `${process.env.REACT_APP_RESOURCE_HOST}/excel/bixby.xlsx`;
  },
  getFilename: () => {
    return instance.get('/bixby/filename');
  },
  uploadTempFile: (formData: any) => {
    return instance.post('/bixby/upload/temp', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  operate: (filename: string) => {
    return instance.post('/bixby/upload', {
      filename,
    });
  },
  cancel: (jobId: string) => {
    return instance.put(`/bixby/temp/${jobId}/cancel`);
  },
};

/**
 * 가수번호 관리
 */
const SINGER_NUMBER_PARAMS = ['id', 'name'];
const singerNumber = {
  list: (params: any) => {
    return instance.get(`/singer${params ? '?' + qs.stringify(params) : ''}`);
  },
  create: async (params: any) => {
    try {
      const newParams = makeSimpleParams(params, SINGER_NUMBER_PARAMS);
      newParams.id = parseInt(newParams.id);
      const res = await instance.post(`/singer`, newParams);
      return res;
    } catch (e: any) {
      console.error('e', e);
      if (e?.response?.data?.status === 400) {
        throw new CustomError(`중복된 가수번호가 존재합니다. 다시한번 확인해주세요.\n${e?.response?.data?.message}`);
      } else {
        throw e;
      }
    }
  },
  detail: (id: string) => {
    return instance.get(`/singer/${id}`);
  },
  update: async (params: any, id: string) => {
    const newParams = makeSimpleParams(params, SINGER_NUMBER_PARAMS);
    newParams.id = parseInt(newParams.id);
    try {
      const res = await instance.put(`/singer/${id}`, newParams);
      return res;
    } catch (e: any) {
      console.error('e', e);
      if (e?.response?.data?.status === 400) {
        throw new CustomError(`중복된 가수번호가 존재합니다. 다시한번 확인해주세요.\n${e?.response?.data?.message}`);
      } else {
        throw e;
      }
    }
  },
  delete: (id: string) => {
    return instance.delete(`/singer/${id}`);
  },
  getJobCurrentType: () => {
    return instance.get(`/job/current/SINGER`);
  },
  sampleExcel: () => {
    return `${process.env.REACT_APP_RESOURCE_HOST}/excel/singer.xlsx`;
  },
  upload: (formData: any) => {
    return instance.post('/singer/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
};

/**
 * 가수명 유사검색어 관리
 */
const SINGER_SIMILAR_PARAMS = ['singerId', 'searchKeyword'];
const singerSimilar = {
  list: (params: any) => {
    return instance.get(`/keyword/singer${params ? '?' + qs.stringify(params) : ''}`);
  },
  create: (params: any) => {
    const newParams = makeSimpleParams(params, SINGER_SIMILAR_PARAMS);
    newParams.singerId = parseInt(newParams.singerId);
    return instance.post(`/keyword/singer`, newParams);
  },
  detail: (id: string) => {
    return instance.get(`/keyword/singer/${id}`);
  },
  update: (params: any, id: string) => {
    const newParams = makeSimpleParams(params, SINGER_SIMILAR_PARAMS);
    newParams.singerId = parseInt(newParams.singerId);
    return instance.put(`/keyword/singer/${id}`, newParams);
  },
  delete: (id: string) => {
    return instance.delete(`/keyword/singer/${id}`);
  },
  getJobCurrentType: () => {
    return instance.get(`/job/current/SINGER_KEYWORD`);
  },
  sampleExcel: () => {
    return `${process.env.REACT_APP_RESOURCE_HOST}/excel/singer-keyword.xlsx`;
  },
  upload: (formData: any) => {
    return instance.post('/keyword/singer/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
};

/**
 * 곡제목 유사검색어 관리
 */
const SONG_SIMILAR_PARAMS = ['songId', 'searchKeyword'];
const songSimilar = {
  list: (params: any) => {
    return instance.get(`/keyword/song${params ? '?' + qs.stringify(params) : ''}`);
  },
  create: async (params: any) => {
    try {
      const newParams = makeSimpleParams(params, SONG_SIMILAR_PARAMS);
      newParams.songId = parseInt(newParams.songId);
      const res = await instance.post(`/keyword/song`, newParams);
      return res;
    } catch (e: any) {
      console.error('e', e);
      if (e?.response?.data?.status === 400) {
        throw new CustomError(`중복된 유사검색어가 존재합니다. 다시한번 확인해주세요.\n${e?.response?.data?.message}`);
      } else {
        throw e;
      }
    }
  },
  detail: (id: string) => {
    return instance.get(`/keyword/song/${id}`);
  },
  update: async (params: any, id: string) => {
    try {
      const newParams = makeSimpleParams(params, SONG_SIMILAR_PARAMS);
      newParams.songId = parseInt(newParams.songId);
      const res = await instance.put(`/keyword/song/${id}`, newParams);
      return res;
    } catch (e: any) {
      console.error('e', e);
      if (e?.response?.data?.status === 400) {
        throw new CustomError(`중복된 유사검색어가 존재합니다. 다시한번 확인해주세요.\n${e?.response?.data?.message}`);
      } else {
        throw e;
      }
    }
  },
  delete: (id: string) => {
    return instance.delete(`/keyword/song/${id}`);
  },
  getJobCurrentType: () => {
    return instance.get(`/job/current/SONG_KEYWORD`);
  },
  sampleExcel: () => {
    return `${process.env.REACT_APP_RESOURCE_HOST}/excel/song-keyword.xlsx`;
  },
  upload: (formData: any) => {
    return instance.post('/keyword/song/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
};

/**
 * 곡정보 관리
 */
const makeSongInfoParams = (params: any) => {
  const newParams = makeSimpleParams(params, [
    'id',
    'title',
    'singer',
    'singerEng',
    'singerId',
    'cCtry',
    'sKeySex',
    'sMKey',
    'sFKey',
    'isrc',
    'iswc',
    'instFlag',
  ]);
  newParams.id = parseInt(newParams.id);
  newParams.singerId = parseInt(newParams.singerId);
  if (newParams['sKeySex'] === options.NOT_SELECT) {
    newParams.sKeySex = null; // 처음부터 ''으로 하면 formik에서는 필수조건에 걸리기 때문에, 일닩 NOT_SELECT 키워드로 통과 후에 api 요청시 처리함.
  }
  // 필수값이 아니므로, 빈값이더라도 api반영해서 update할 수 있도록!
  newParams['sWriter'] = params['sWriter'];
  newParams['sCompos'] = params['sCompos'];
  return newParams;
};
const songInfo = {
  list: (params: any) => {
    return instance.get(`/song${params ? '?' + qs.stringify(params) : ''}`);
  },
  create: async (params: any) => {
    try {
      const newParams = makeSongInfoParams(params);
      const res = await instance.post(`/song`, newParams);
      return res;
    } catch (e: any) {
      console.error('e', e);
      if (e?.response?.data?.status === 400) {
        throw new CustomError(`중복된 유사검색어가 존재합니다. 다시한번 확인해주세요.\n${e?.response?.data?.message}`);
      } else {
        throw e;
      }
    }
  },
  detail: (id: string) => {
    return instance.get(`/song/${id}`);
  },
  update: async (params: any, id: string) => {
    try {
      const newParams = makeSongInfoParams(params);
      const res = await instance.put(`/song/${id}`, newParams);
      return res;
    } catch (e: any) {
      console.error('e', e);
      if (e?.response?.data?.status === 400) {
        throw new CustomError(`중복된 유사검색어가 존재합니다. 다시한번 확인해주세요.\n${e?.response?.data?.message}`);
      } else {
        throw e;
      }
    }
  },
  delete: (id: string) => {
    return instance.delete(`/song/${id}`);
  },
  getJobCurrentType: () => {
    return instance.get(`/job/current/SONG`);
  },
  sampleExcel: () => {
    return `${process.env.REACT_APP_RESOURCE_HOST}/excel/song.xlsx`;
  },
  upload: (formData: any) => {
    return instance.post('/song/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  getMusicKeys: async () => {
    const res: any = await instance.get(`/song/key`);
    if (res.list) {
      const list = res.list.map((obj: any) => ({
        label: obj.value,
        value: obj.value,
      }));
      return list;
    }
    return null;
  },
  getCountry: async () => {
    const res: any = await instance.get(`/song/country`);
    if (res.list) {
      const list = res.list.map((obj: any) => ({
        label: obj.value,
        value: obj.value,
      }));
      return list;
    }
    return null;
  },
};

/**
 * 어휘사전
 */
const mutationVocabulary = async (method: 'post' | 'put', url: string, params: any) => {
  try {
    const newParams = makeSimpleParams(params, ['representation', 'words']);
    if (newParams['words'] === '') {
      newParams['words'] = [];
    }
    newParams['words'].push(newParams['representation']);
    newParams['words'] = uniq(newParams['words']);
    const res = await instance({
      method,
      url,
      data: newParams,
    });
    return res;
  } catch (e: any) {
    console.error('e', e);
    if (e?.response?.data?.status === 400) {
      throw new CustomError(`중복된 대표단어가 존재합니다. 다시한번 확인해주세요.\n${e?.response?.data?.message}`);
    } else {
      throw e;
    }
  }
};
const vocabulary = {
  list: (params: any, pageName: any, tvBrand: any, queryObj: any) => {
    if (queryObj.tab !== 'title') {
      // default가 singer라서 title이 아니면 무조건 singer로 조회
      return instance.get(`/keyword/bixby/singer${params ? '?' + qs.stringify(params) : ''}`);
    } else {
      return instance.get(`/keyword/bixby/song${params ? '?' + qs.stringify(params) : ''}`);
    }
  },
  getJobCurrentType: () => {
    return instance.get(`/job/current/BIXBY_KEYWORD`);
  },
  sampleExcel: () => {
    return `${process.env.REACT_APP_RESOURCE_HOST}/excel/bixby-voca.xlsx`;
  },
  upload: (formData: any) => {
    return instance.post('/keyword/bixby/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  title: {
    create: (params: any) => {
      return mutationVocabulary('post', '/keyword/bixby/song', params);
    },
    detail: (id: string) => {
      return instance.get(`/keyword/bixby/song/${id}`);
    },
    update: async (params: any, id: string) => {
      return mutationVocabulary('put', `/keyword/bixby/song/${id}`, params);
    },
    delete: (id: string) => {
      return instance.delete(`/keyword/bixby/song/${id}`);
    },
  },
  singer: {
    create: (params: any) => {
      return mutationVocabulary('post', '/keyword/bixby/singer', params);
    },
    detail: (id: string) => {
      return instance.get(`/keyword/bixby/singer/${id}`);
    },
    update: async (params: any, id: string) => {
      return mutationVocabulary('put', `/keyword/bixby/singer/${id}`, params);
    },
    delete: (id: string) => {
      return instance.delete(`/keyword/bixby/singer/${id}`);
    },
  },
};

/**
 * DetailField에서 직접 사용하는 api
 */
const uploadContentsImage = (formData: any) => {
  return instance.post('/curation/upload/thumbnail', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
const uploadMainBannerImage = (formData: any) => {
  return instance.post('/banner/upload/thumbnail', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
const uploadNoticeImage = (formData: any) => {
  return instance.post('/notice/upload/image', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
interface IProduct {
  category?: string;
  subscription?: boolean;
}
const getProduct = async (params: IProduct) => {
  const newParams = makeSimpleParams(params, ['category', 'subscription']);
  const res: any = await instance.get(`/product/list${newParams ? '?' + qs.stringify(newParams) : ''}`);
  if (res.list) {
    return res.list;
  }
  return null;
};
const getAllProductOptions = async () => {
  let list = await getProduct({});
  if (!list) return null;
  /**
   * [임시]
   * 카테고리별 정렬은 서버쪽에서 해주면 나을듯한데 일단 프론트 쪽에서 처리
   * CATEGORY 값에 대한 유저용 type이 따로 없어서 아래와 같이 임시로 처리
   */
  list = sortBy(list, (item: any) => item.category);
  list = list.map((obj: any) => {
    let categoryEnumTest = obj.category as CATEGORY;
    const categoryObj = options.promotionCategoryList.find((e) => e.value === categoryEnumTest);
    // OR Use obj.category
    return {
      label: `[${categoryObj?.label}][${obj.region}] ${obj.koName}`,
      value: obj.id,
    };
  });
  return list;
};
const songSearch = (params: any) => {
  return instance.get(`/song/search${params ? '?' + qs.stringify(params) : ''}`);
};
const getJobStatus = (type: string) => {
  return instance.get(`/job/${type}`);
};

const cacheRefresh = async () => {
  return instance.post('/cache/refresh');
};

const syncData = async () => {
  return instance.post('/resource/sync');
};

export default {
  auth,
  contents,
  mainBanner,
  notice,
  member,
  payment,
  promotion,
  terms,
  musicRecommendUpload,
  bixbyUpload,
  singerNumber,
  singerSimilar,
  songSimilar,
  songInfo,
  songLog,
  vocabulary,
  uploadContentsImage,
  uploadMainBannerImage,
  uploadNoticeImage,
  getProduct,
  getAllProductOptions,
  songSearch,
  getJobStatus,
  cacheRefresh,
  syncData,
  videoSwitch,
};
