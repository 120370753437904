import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { get } from 'lodash-es';

import SearchModule from '@/modules/SearchModule';
import SearchField from '@/components/SearchField';
import options from '@/constants/options';
import { CATEGORY } from '@/constants/constants';
import api from '@/api';
import { Button } from 'react-bootstrap';

export default function Component(props: any) {
  const location = useLocation();
  return (
    <>
      <SearchModule
        {...props}
        api={api.songLog}
        onSearchValidation={(params: any) => {
          if (params.customDateRangeWithType.startDate || params.customDateRangeWithType.endDate) {
            if (!params.customDateRangeWithType.startDate || !params.customDateRangeWithType.endDate) {
              alert('시작날짜와 종료날짜 모두 선택해주세요.');
              return false;
            }
          }
          return true;
        }}
        styleTemplate="template-promotion"
        inputFormat={[
          {
            title: '이메일',
            keyId: 'email',
            field: SearchField.Input({
              placeholder: '사용자 이메일로 검색하실 수 있습니다.',
            }),
          },
          {
            title: '국적',
            keyId: 'country',
            field: SearchField.SelectBox({ api: api.member.getCountry }),
          },
          {
            title: 'TV 브랜드',
            keyId: 'tvBrand',
            field: SearchField.SelectBox({
              list: [
                { label: '전체', value: '' },
                { label: 'SAMSUNG', value: 'samsung' },
                { label: 'LG', value: 'lg' },
              ],
            }),
          },
          {
            title: '날짜',
            keyId: 'customDateRangeWithType',
            field: SearchField.CustomDateRangeWithType(true),
            defaultValue: {
              dateType: options.dateTypeList[0].value,
              startDate: `${new Date().getFullYear()}-${
                new Date().getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1
              }-01`,
              endDate: `${new Date().getFullYear()}-${
                new Date().getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1
              }-${new Date().getDate() < 10 ? '0' + new Date().getDate() : new Date().getDate()}`,
            },
          },
        ]}
        tableFormat={{
          // theads: ['이메일', '국적', '곡 번호', '곡명', '가수', '자동결제', '하위프로필', '등록일', '최근접속일'],
          theads: () => (
            <>
              <tr>
                <th>사용자 아이디</th>
                <th>이메일</th>
                <th>TV 브랜드</th>
                <th>국적</th>
                <th>곡 번호</th>
                <th>곡명</th>
                <th>가수</th>
                <th>작사</th>
                <th>작곡</th>
                <th>isrc</th>
                <th>iswc</th>
                <th>상품명</th>
                <th>프로모션</th>
                <th>날짜</th>
              </tr>
            </>
          ),
          columns: [
            // (item: any) => (
            //   <a href={`${location.pathname}/info/${item.id}`}>{item.email}</a>
            // ),
            // 'country',
            (item: any) => item.userId,
            (item: any) => item.email,
            (item: any) => item.tvBrand.split('_')[0],
            (item: any) => item.country,
            (item: any) => item.songId,
            (item: any) => item.title,
            (item: any) => item.singer,
            (item: any) => item.sWriter,
            (item: any) => item.sCompos,
            (item: any) => item.isrc,
            (item: any) => item.iswc,
            (item: any) => item.product,
            (item: any) => (item.usedPromotion ? 'y' : 'n'),
            (item: any) => item.regDate,
          ],
        }}
        customCreateButton={<></>}
        bottomLeftMenu={({ totalDataCount, params }: any) => {
          if (totalDataCount === 0) return <></>;
          return (
            <Button
              size="sm"
              variant="primary"
              onClick={() => {
                api.songLog.downloadListToExcel(params);
              }}
            >
              결과 다운로드
            </Button>
          );
        }}
      />
    </>
  );
}
