import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { get } from 'lodash-es';
import qs from 'qs';
import SearchModule from '@/modules/SearchModule';
import SearchField from '@/components/SearchField';
import options from '@/constants/options';
import { CATEGORY } from '@/constants/constants';
import api from '@/api';
import { Button } from 'react-bootstrap';

export default function Component(props: any) {
  const location = useLocation();

  const currentSearchTvBrand = useMemo(() => {
    const queryString = qs.parse(location.search, { ignoreQueryPrefix: true });
    return queryString.tvBrand || '';
  }, [location.search]);

  return (
    <>
      <SearchModule
        {...props}
        api={api.payment}
        onSearchValidation={(params: any) => {
          if (params.customDateRangeWithType.startDate || params.customDateRangeWithType.endDate) {
            if (!params.customDateRangeWithType.startDate || !params.customDateRangeWithType.endDate) {
              alert('시작날짜와 종료날짜 모두 선택해주세요.');
              return false;
            }
          }
          return true;
        }}
        styleTemplate="template-member"
        inputFormat={[
          {
            title: '날짜',
            keyId: 'customDateRangeWithType',
            field: SearchField.CustomDateRangeWithType(false, options.paymentDateTypeList),
            defaultValue: {
              dateType: options.paymentDateTypeList[0].value,
              startDate: null,
              endDate: null,
            },
          },
          {
            title: 'TV 브랜드',
            keyId: 'tvBrand',
            field: SearchField.SelectBox({
              list: [
                { label: '전체', value: '' },
                { label: 'SAMSUNG', value: 'SAMSUNG' },
                { label: 'LG', value: 'LG' },
              ],
            }),
          },
        ]}
        tableFormat={{
          // theads: ['이메일', '국적', '현재활성상품', '자동결제', '현재활성상품', '자동결제', '하위프로필', '등록일', '최근접속일'],
          theads: () => (
            <>
              <tr>
                <th>이메일</th>
                <th>국적</th>
                <th>TV 브랜드</th>
                <th>상품명</th>
                <th>결제금액</th>
                <th>프로모션금액</th>
                <th>결제방식</th>
                <th>결제일</th>
              </tr>
            </>
          ),
          columns: [
            'user_email',
            'user_country',
            (item: any) => (item.user_type === 'LG_BRANDED' ? 'LG' : 'Samsung'),
            'product_koName',
            'payment_amount',
            'payment_saleAmount',
            'payment_payMethod',
            'payment_paymentDate',
          ],
        }}
        customCreateButton={<></>}
        bottomLeftMenu={({ totalDataCount, params }: any) => {
          if (totalDataCount === 0) return <></>;
          return (
            <Button
              size="sm"
              variant="primary"
              onClick={() => {
                api.payment.downloadListToExcel(params);
              }}
            >
              결과 다운로드
            </Button>
          );
        }}
      />
    </>
  );
}
